export default {
    methods: {
        /* eslint-disable */
        getPaymentParams: function (srcType = 'card', isApplePay = false) {
            const {isNeedComission} = window.settings;
            const isInvoiceId = this.invoiceId;
            const paymentParam = isInvoiceId ? 'invoiceId' : 'paymentId';
            const fromCardParams = {
                'src.pan': this.payment.card.pan,
                'src.expiry': `${this.payment.card.expiry.month}${this.payment.card.expiry.year}`,
                'src.csc': this.payment.card.cvv,
            };
            const requireParams = {
                token: `&token=${this.token}&${paymentParam}=${this.paymentId}`
            }
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const redirectUrl = urlParams.get('redirectUrl') || null;

            let params = {
                paymentId: this.paymentId,
                amount: this.payment.amount * 100,
                commission: isNeedComission ? this.comission * 100 : 0,
                currency: window.settings.currency,
                'src.type': srcType,
                '3ds2.supported': window.settings.threeDSV2Supported,
                returnUrl: redirectUrl ? `${redirectUrl}${requireParams.token}` :
                    `${window.location.origin}/?state=bill${requireParams.token}`,
            };
            if(this.invoiceId) {
                params.invoiceId = this.invoiceId;
                delete params['paymentId'];
            }
            if (fromCardParams) {
                params = { ...params, ...fromCardParams }
            }
            return params;
    }
  }
}