<template>
  <div class="error-wrapper">
    <img width="98px" :src="require(`@/assets/${type}.svg`)"/>
    <div class="error-text">{{errorText}}</div>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
  props: {
    type: String
  },
  computed: {
    errorText: function () {
      let text = '';
      if (this.type === 'link') {
        text = 'Некорректная ссылка'
      }

      if (this.type === 'oops') {
        text = 'Что-то пошло не так'
      }

      if (this.type === '404') {
        text = 'Страница не найдена'
      }
      return text;
    }
  }
}
</script>

<style scoped>
  .error-wrapper {
      background: #E4EDF9;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }

  .error-text {
      color: #B6CDEF;
      font-size: 17px;
      line-height: 21px;
      
  }
</style>
