export default {
    header: {
        title: 'Payment of tips'
    },
    amount: {
        title: 'Tip amount',
        placeholder: 'Other amount',
        min: 'Minimum amount'
    },
    pan: {
        title: 'Your card number'
    },
    expiry: {
        date: 'Expiry'
    },
    footer: {
        button: {
            pay: 'Pay',
            comission: 'Including commission',
            applePay: 'Pay with'
        }
    },
    bill: {
        success: {
            title: 'Payment was successful'
        },
        error: {
            title: 'Try to repeat the translation later',
            description: 'An error occurred during the transaction, please try again later'
        },
        button: 'Close'
    },
    offer: {
        agree: 'By making a payment, you agree to the terms of the',
        link: 'offer'
    }
}