<template>
  <div class="amount">
    <div class="amount-label">{{amountDescription}}</div>
    <div class="amount-items-wrapper" v-if="isAmountListEnable">
        <div @click="setAmount(amountsList[0])" class="amount-item">{{ amountsList[0] }} {{currencyView}}</div>
        <div @click="setAmount(amountsList[1])" class="amount-item amount-active">{{ amountsList[1] }} {{currencyView}}</div>
        <div @click="setAmount(amountsList[2])" class="amount-item">{{ amountsList[2] }} {{currencyView}}</div>
    </div>
    <IV-input
        @input="amountChanged"
        :classes="['amount-input card-input']"
        :wrapClasses="['amount-wrapper']"
        :placeholder="$t('amount.placeholder')"
        :resetValue="isReset"
        :maskPattern="'#####'"
        :label="''"
        :isError="isAmountError"
        :postfixText="currencyView"
        :bottomLabel="`${$t('amount.min')} - ${minAmount} ${currencyView}`"
        :defaultValue="defaultValue"
    />
  </div>
</template>

<script>
import IVInput from './Base/IVInput.vue';
import { MIN_AMOUNT } from '../utils/constants';
import { AMOUNTS_LIST, CURRENCY_VIEW } from '../utils/constants'; 
const ACTIVE_CLASS = 'amount-active';

export default {
  name: 'Amount',
  components: { IVInput },
  data: function () {
    return {
      isReset: false,
      isAmountError: false,
      minAmount: MIN_AMOUNT,
      amountsList: AMOUNTS_LIST,
      currencyView: CURRENCY_VIEW,
      amountDescription: window.settings.amountDescription,
      isAmountListEnable: window.settings.isAmountListEnable,
      defaultValue: null
    } 
  },
  created() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      this.defaultValue = urlParams.get('amount') || null;
      
      if (this.defaultValue) {
        this.amountChanged(this.defaultValue);
      }
  },
  methods: {
    amountChanged(amount) {
      this.removeActiveClass();
      this.$emit('amountChanged', parseInt(amount));
      (parseInt(amount) < MIN_AMOUNT) ? this.isAmountError = true : this.isAmountError = false;
      this.isReset = false;
    },
    removeActiveClass() {      
        let activeAmount = document.getElementsByClassName(ACTIVE_CLASS) || [];
        if (activeAmount[0]) {
            activeAmount[0].classList.remove(ACTIVE_CLASS);
        }
    },
    setAmount(amount) {
        this.removeActiveClass()
        event.target.classList.add(ACTIVE_CLASS);
        this.$emit('amountChanged', amount);
        this.isReset = true;
        this.isAmountError = false;
    }
  }
}
</script>

<style scoped>
  .amount {
      margin-top: var(--main-amount-margine-top);
  }
  .amount-label {
      font-size: 13px;
  }
  .amount-items-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 43px;
    width: 99%;
    margin-top: var(--main-amount-items-margine-top);
  }

  .amount-item {
      cursor: pointer;
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      font-size: var(--main-amount-item-font-size);
      line-height: 43px;
      text-align: center;
      width: 32%;
      border-radius: var(--main-block-border-radius);
      box-shadow: var(--main-block-box-shadow);
      background-color: #FCFCFC;
  }

  .amount-active {
      background-color: var(--main-amount-item-active-background-color);
      color: white;
  }
</style>
