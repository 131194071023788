<template>
  <div>
    <loader v-if="isLoading"/>
    <div class="payment-page-wrapper">
        <div>
          <div class="header">
              <header-shell :msg="$t('header.title')"/>
              <logo/>
          </div>
          <div class="content-wrapper">
              <payment-type @paymentTypeChanged="payment.type = $event" v-if="isApplePayAvailable"/>
              <amount @amountChanged="amountChanged"/>
              <card :card="payment.card" :errors="errors.card" v-if="payment.type === 'card'"/>
          </div>
          <div class="footer">
            <offer v-if="isNeedOffer"/>
            <pay-button :type="payment.type" 
              :availableStart="isAvailableStart"
              :amount="payment.amount" 
              :comission="comission" 
              @click="startPayment"
              :isLoading="isButtonLoadingSpinner"
            />
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import HeaderShell from './HeaderShell.vue'
import PaymentType from './PaymentType.vue'
import Logo from './Logo.vue'
import Amount from './Amount.vue'
import Card from './Card.vue'
import Offer from './Offer.vue'
import PayButton from './PayButton.vue'
import Loader from './Loader.vue'
import iframe3dsMixin from '../mixins/3dsmixin';
import paymentStatus from '../mixins/paymentStatus';
import payment from '../mixins/payment';
import comissionMixin from '../mixins/comission';
import { MIN_AMOUNT } from '../utils/constants'; 

export default {
  name: 'PaymentPage',
  mixins: [iframe3dsMixin, paymentStatus, payment, comissionMixin],
  components: {
    HeaderShell,
    PaymentType,
    Card,
    Amount,
    Logo,
    Offer,
    PayButton,
    Loader
  },
  props: {
    paymentId: String,
    invoiceId: String,
  },
  data: function () {
    return {
      isApplePayAvailable: window.ApplePaySession,
      token: null,
      isLoading: false,
      isNeedComission: window.settings.isNeedComission,
      isNeedOffer: window.settings.isNeedOffer,
      errors: {
          card: {
            pan: false,
            expiry: false,
            cvv: false
          }
      },
      comission: null,
      payment: {
          amount: window.settings.isAmountListEnable ? window.settings.amountList[1] : 0,
          type: 'card',
          card: {
            brand: 'default',
            pan: '',
            expiry: {
                month: '',
                year: ''
            },
            cvv: ''
          }
      },
      isButtonLoadingSpinner: false
    }
  },
  async created() {
    this.isLoading = false;
    this.amountChanged(this.payment.amount);
  },
  computed: {
    isAvailableStart: function () {
      let isAvailable = false;
      if(this.payment.type === 'card') {
        isAvailable = !!(!this.errors.card.pan && !this.errors.card.expiry && !this.errors.card.cvv &&
            this.payment.amount >=  MIN_AMOUNT &&
            this.payment.amount && this.payment.card.pan &&
            this.payment.card.expiry.month && this.payment.card.expiry.year &&
            (this.payment.card.brand === 'maestro' ? ( this.payment.card.cvv.length === 3 || this.payment.card.cvv.length === 0 )   : this.payment.card.cvv));
      }
      if(this.payment.type === 'applePay') {
        isAvailable = !!this.payment.amount;
      }
      
      return isAvailable;
    }
  },
  methods: {
    amountChanged: async function(amount) {
      this.payment.amount = amount;
      if(this.isNeedComission && this.payment.amount >=  MIN_AMOUNT) {
        const paymentParams = this.getPaymentParams(null, true);
        try {
          this.isButtonLoadingSpinner = true;
          const terms = await this.$http.post(`payment/terms`, paymentParams);
          this.isButtonLoadingSpinner = false;

          const amountTerms = terms.data.amount;
          const comissionInfo = this.getCommission(this.payment.amount, amountTerms);
          this.comission = comissionInfo.comission;
        } catch(e) {
          console.log(e);
        }
      }
    },
    startApplePay: async function() {
      if( this.payment.amount < MIN_AMOUNT ) {
        return;
      }
      let payment = {};
      let paymentRequest = {
          countryCode: 'RU',
          currencyCode: window.settings.currency,
          total: {
              label: 'Well Done',
              amount: this.payment.amount
          },
          supportedNetworks:[ 'amex', 'discover', 'masterCard', 'visa'],
          merchantCapabilities: [ 'supports3DS' ]
      };
      
      let session = new window.ApplePaySession(1, paymentRequest);
      payment.applePaySession = session;

      //Merchant Validation
      session.onvalidatemerchant = async (event) => {
          let validationURL = event.validationURL;
          const tokenResp = await this.$http.post('/token');
          const { token } = tokenResp.data;
          this.token = token;
          try {
            const paymentParams = this.getPaymentParams(null, true);
            await this.$http.post(`payment/${this.token}/start`, paymentParams);
          } catch(e) {
            payment.applePaySession.abort();
          }
          try {
            const validateResp = await this.$http.post(`payment/${this.token}/applepay/validate`, {merchantValidationUrl: validationURL});
            payment.applePaySession.completeMerchantValidation(validateResp.data);
          } catch(e) {
            payment.applePaySession.abort();
            await this.$http.post(`payment/${this.token}/decline`);
            const stateResp = await this.$http.get(`payment/${this.token}`);
            await this.checkStatePayment(stateResp, this.token);
          }
      }
      // Send payment for processing...
      session.onpaymentauthorized = async (event) => {
          const appleToken = event.payment;
          const headers = {
            'Content-Type': 'application/json',
          }
          try {
            const stateResp = await this.$http.post(`payment/${this.token}/applepay/accept`, JSON.stringify(appleToken), headers);
            payment.applePaySession.completePayment(window.ApplePaySession.STATUS_SUCCESS);
            this.checkStatePayment(stateResp, this.token);
          } catch(e) {
            await this.$http.post(`payment/${this.token}/decline`);
            const stateResp = await this.$http.get(`payment/${this.token}`);
            await this.checkStatePayment(stateResp, this.token);
            payment.applePaySession.completePayment(window.ApplePaySession.STATUS_FAILURE);
          }
      };

      session.begin();
    },
    startPayment: async function () {
      if(!this.isAvailableStart) {
          return;
      }
      try {
        if(this.payment.type === 'applePay') {
          await this.startApplePay();
          this.isLoading = false;
          return;
        }
        this.isLoading = true;
        try {
            const tokenResp = await this.$http.post('/token');
            const { token } = tokenResp.data;
            this.token = token;
            const paymentParams = this.getPaymentParams();
            const paymentResp = await this.$http.post(`payment/${this.token}/start`, paymentParams);
            await this.checkStatePayment(paymentResp, this.token);
        } catch(e) {
            console.log(e);
        }
        
        this.isLoading = false; 
      } catch(e) {
          this.isLoading = false;
          console.log(e);
      }
    }
  }
}
</script>
<style scoped>
  .payment-page-wrapper {
    height: 100vh;
  }

  .payment-page-wrapper > div {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.8fr 3fr 1fr;
    grid-template-areas: 
      "header"
      "content-wrapper"
      "footer";
  }

  .header {
    grid-area: header;
  }

  .content-wrapper,
  .footer {
    display: flex;
    flex-direction: column;
  }

  .content-wrapper {
    justify-content: flex-start;
    grid-area: content-wrapper;
  }

  .footer {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 120px;
    justify-content: flex-end;
    grid-area: footer;
  }
</style>
