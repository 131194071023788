<template>
  <div class="header">
    <span class="text">{{msg}}</span>
  </div>
</template>

<script>
export default {
  name: 'HeaderShell',
  props: {
    msg: String
  }
}
</script>

<style scoped>
  .header {
    color: black;
    height: 60px;
    text-align: center;
    width: 100%;
  }

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    display: var(--main-payment-page-description);
    position: relative;
    text-align: center;
    letter-spacing: 0.16px;
    top: 35%;
  }
</style>
