<template>
  <div class="bill-wrapper">
    <div class="bill-content-wrapper">
        <img width="80px" :src="require(`@/assets/${status}.svg`)"/>
        <div class="message-text">{{messageText}}</div>
        <div v-if="status === 'done'" class="amount">{{(billData.amount + billData.commission) / 100}} {{currencyView}}</div>
        <div class="description-text">{{descriptionText}}</div>
        <div class="support-email" v-if="status === 'unknown'">{{supportEmail}}</div>
    </div>
    <div class="button" @click="close">{{ $t('bill.button') }}</div>
  </div>
</template>

<script>
import { CURRENCY_VIEW } from '../utils/constants'; 
export default {
  name: 'Bill',
  data: function () {
    return {
      supportEmail: window.settings.supportEmail,
      currencyView: CURRENCY_VIEW
    }
  },
  props: {
    billData: Object,
    token: String,
    paymentId: String
  },
  computed: {
    descriptionText: function() {
        let message = this.billData.result && this.billData.result.message ? this.billData.result.message : '';
        if (this.billData.result && this.billData.result.status && this.billData.result.status === 'DECLINED') {
          message = this.$t('bill.error.description');
        }
        return message;
    },
    messageText: function() {
        return this.status === 'done' ? this.$t('bill.success.title') : this.$t('bill.error.title')
    },
    status: function () {
      let status = 'error'
      if(this.billData.result && this.billData.result.status === 'SUCCESS') {
          status = 'done';
      }
      if(this.billData.result && this.billData.result.status === 'UNKNOWN') {
          status = 'unknown';
      }
      if (window.parent) {
        const message = this.billData.result.status;
        window.parent.postMessage(message, "*");
      }

      return status;
    }
  },
  methods: {
    close() {
        this.$emit('changeStep', { state: 'payment' });
        const redirectParam = this.billData.invoice ? 'invoiceId' : 'paymentId';
        window.history.pushState({}, document.title, `/?${redirectParam}=${this.paymentId}`);
    }
  }
}
</script>

<style scoped>
  .message-text {
      font-size: 17px;
      line-height: 21px;
      font-weight: 500;
      margin-top: 17px;
      text-align: center;
      padding-left: 30px;
      padding-right: 30px;
      color: var(--main-bill-font-color);
  }
  .bill-wrapper {
      background: var(--main-bill-background-color);
      display: flex;
      height: 100vh;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  .amount {
      font-size: 28px;
      margin-top: 17px;
      font-weight: 500;
      color: var(--main-bill-font-color);
  }
  .description-text {
      margin-top: 17px;
      line-height: 18px;
      font-size: 14px;
      text-align: center;
      width: 70%;
      color: var(--main-bill-font-color);
  }
  .bill-content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20%;
  }
  .button {
      position: absolute;
      bottom: 5%;
      display: flex;
      color: white;
      background: var(--main-bill-button-color);
      border-radius: 8px;
      height: 52px;
      width: 90%;
      justify-content: center;
      align-items: center;
  }
  .support-email {
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    margin-top: 17px;
  }
</style>
