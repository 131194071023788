<template>
  <div class="logo-wrapper">
    <div class="logo">
        <img :src="logoImg">
    </div>
    <!-- <div class="company-name">Well Done</div> -->
  </div>
</template>

<script>
export default {
  name: 'Logo',
  data: function () {
    return {
      logoImg: window.settings.logo
    }
  }
}
</script>

<style lang="scss" scoped>
  .logo {
      display: flex;
      justify-content: center;

      img {
        width: var(--main-logo-width);
        height: var(--main-logo-height);
      }
  }
  .company-name {
      font-size: 20px;
      font-weight: 500;
      margin-top: 16px;
      width: 100%;
      text-align: center;
      margin-top: 18px;
  }
</style>
