export default {
  mounted() {
    this.$on('iframe:destroyed', async (token) => {
      this.isLoading = true;
      const stateResp = await this.$http.get(`payment/${token}`);
      await this.checkStatePayment(stateResp, token);
    });
  },
  methods: {
    redirectToIframe(response, token, routerName = 'bill') {
        response = response.data;
        if(response.state === 'redirect' && response.url && !response.post) {
            window.location.href = response.url;
        }
        const appDiv = document.querySelector('#app');
        const frame = this.createIframe();

        if (!response.post) {
            frame.src = response.url;
        }

        frame.onload = () => {
            const isFrameHidden = response.iframeMode && response.iframeMode === 'HIDDEN'; //3dsV2
            if (!isFrameHidden) {
                this.isLoading = true;
            }
            const isSameOrigin = frame.contentWindow.location.href &&
            frame.contentWindow.location.href.includes(window.location.origin);

            if (isSameOrigin) {
              appDiv.removeChild(frame);
              // eslint-disable-next-line
              response.post
                  ? this.$emit('iframe:destroyed', token)
                  // : this.$router.push({ name: routerName, params: { token } });
                  : console.log('PUSH TO BILL NEED', routerName)
            }
        };

        appDiv.appendChild(frame);

        if (response.post) {
            const formElement = this.createForm(response.url);
            formElement.id = 'postForm';

            this.attachInput(response.post, formElement);

            frame.appendChild(formElement);

            const frameDocument = frame.contentWindow.document || frame.contentDocument;
            frameDocument.body.appendChild(formElement);
            frameDocument.getElementById('postForm').submit();
        }
    },
    createIframe() {
      const iframe = document.createElement('iframe');
      iframe.setAttribute('frameborder', '0');
      iframe.width = '100%';
      iframe.height = '100%';
      iframe.style.position = 'absolute';
      iframe.style.top = '0';
      iframe.style.zIndex = '1031';
      iframe.style.backgroundColor = '#ffffff';
      return iframe;
    },
    createForm(url) {
      const form = document.createElement('form');
      form.action = url;
      form.method = 'POST';
      form.target = '_self';

      const submit = document.createElement('input');
      submit.setAttribute('type', 'submit');
      submit.style.display = 'none';

      form.appendChild(submit);
      return form;
    },
    attachInput(postParams, form) {
      Object.keys(postParams)
        .forEach((key) => {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = key;
          input.value = postParams[key];
          form.appendChild(input);
        });
    },
  },
};
