export default {
    header: {
        title: 'Оплата чаевых'
    },
    amount: {
        title: 'Сумма чаевых',
        placeholder: 'Другая сумма',
        min: 'Минимальная сумма'
    },
    pan: {
        title: 'Номер вашей карты'
    },
    expiry: {
        date: 'Срок действия'
    },
    footer: {
        button: {
            pay: 'Оплатить',
            comission: 'С учетом комиссии',
            applePay: 'Оплатить с помощью'
        }
    },
    bill: {
        success: {
            title: 'Оплата прошла успешно'
        },
        error: {
            title: 'Попробуйте повторить перевод через некоторое время',
            description: 'Во время проведения транзакции произошла ошибка, попробуйте позже'
        },
        button: 'Закрыть'
    },
    offer: {
        agree: 'Совершая платеж, вы соглашаетесь с условиями',
        link: 'оферты'
    }
}