<template>
  <div class="loader-wrapper">
    <clip-loader :loading="true"></clip-loader>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  name: 'Loader',
  components: { ClipLoader }
}
</script>

<style scoped>
    .loader-wrapper {
        position: fixed;
        display: flex;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 2;
        align-items: center;
        justify-content: center;
    }
</style>
