<template>
  <div class="input-wrapper">
        <div class="label" :class="isError ? 'error-label' : ''">{{label}}</div>
        <div :class="wrapperClasses">
            <input @blur="action('blur')"
                  @focus="action('focus')"
                  @change="action('change')"
                  @input="$emit('input', value)"
                  v-model="value"
                  v-maska="maskPattern"
                  type="tel"
                  :disabled="disabled"
                  :placeholder="placeholder"
                  :class="inputClasses"/>
            <div v-if="postfixImage" class="brand"><img :src="require(`@/assets/brands/${postfixImage}.svg`)"/></div>
            <div v-if="postfixText" class="postfix-text">{{postfixText}}</div>
        </div>
        <div v-if="bottomLabel" class="label bottom-label">{{bottomLabel}}</div>
  </div>
</template>

<script>
import { maska } from 'maska'

const ACTIVE_CLASS = 'active';
const ERROR_CLASS  = 'error-input';

export default {
  name: 'IVInput',
  directives: { maska },
  props: {
    isError: Boolean,
    maskPattern: String,
    placeholder: String,
    wrapClasses: Array,
    classes: Array,
    label: String,
    postfixImage: String,
    disabled: Boolean,
    resetValue: Boolean,
    bottomLabel: String,
    postfixText: String,
    defaultValue: String
  },
  data: function () {
    return {
      isFocused: false,
      value: this.defaultValue || null
    }
  },
  computed: {
    wrapperClasses: function() {
      let wrapperClasses = this.wrapClasses;
      this.isFocused && this.postfixImage ? wrapperClasses.push(ACTIVE_CLASS) : wrapperClasses.remove(ACTIVE_CLASS);
      this.isError && this.postfixImage ? wrapperClasses.push(ERROR_CLASS) : wrapperClasses.remove(ERROR_CLASS);
      return wrapperClasses.join(' ');
    },
    inputClasses: function () {
      let inputClasses = this.classes;
      this.isError && !this.postfixImage ? inputClasses.push(ERROR_CLASS) : inputClasses.remove(ERROR_CLASS);
      this.isFocused && !this.postfixImage ? inputClasses.push(ACTIVE_CLASS) : inputClasses.remove(ACTIVE_CLASS);
      return inputClasses.join(' ');
    }
  },
  methods: {
    action(type) {
        this.isFocused = type === 'focus' ? true : false
        this.$emit(type, this.value)
    }
  },
  watch: {
    resetValue: function (val) {
      if(val === true) this.value = null;
    }
  }
}
</script>

<style scoped>
    .label {
        font-size: 13px;
        line-height: 17px;
    }
    .amount-wrapper {
      margin-top: var(--main-amount-wrapper-margin-top);
      border-radius: var(--main-amount-wrapper-border-radius);
      box-shadow: var(--main-amount-wrapper-box-shadow);
    }
    .active {
        border: 1px solid var(--main-button-color);
    }
    .error-label {
        color: #EE4040
    }
    .error-input {
        border-color: #EE4040
    }
    .bullet-text::placeholder {
      font-family: Bullet, 'sans-serif';
    } 
    .bullet-text {
      font-family: bullet;
      font-size: inherit;
      line-height: inherit;
    }
    .bottom-label {
      color: #8E8E8E;
      font-size: 10px;
    }
    .postfix-text {
      position: absolute;
      top: 27px;
      right: 16px;
    }
</style>
