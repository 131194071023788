<template>
  <div class="pay-button-wrapper">
    <div v-if="type === 'card'" class="button" :class="availableStart ? '' : 'inactive'" @click="$emit('click')">
        <span v-if="!isLoading">{{ $t('footer.button.pay') }} {{humanAmount}}</span>
        <span v-if="comission && !isLoading" class="comission-text">{{$t('footer.button.comission')}} {{ humanComission }}</span>
        <Spinner
          :loading="isLoading"
        />
    </div>
    <div lang=ru v-if="type === 'applePay'" class="apple-pay-button apple-pay-button-black" :class="availableStart ? '' : 'inactive'" @click="$emit('click')">
        <span class="text">{{ $t('footer.button.applePay') }}</span>
        <span class="logo"></span>
    </div>
  </div>
</template>

<script>
import Spinner from 'vue-spinner/src/ClipLoader.vue';
import { CURRENCY_VIEW } from '../utils/constants'; 

export default {
  name: 'PayButton',
  components: {
    Spinner
  },
  data: function () {
    return {
      currencyView: CURRENCY_VIEW
    }
  },
  props: {
    amount: Number,
    availableStart: Boolean,
    type: String,
    comission: {
      type: Number,
      required: false,
      default: function() {
        return 0;
      }
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    humanAmount: function () {
      return this.amount ? `${this.amount + this.comission}${this.currencyView}` : ''
    },
    humanComission: function() {
      return this.comission ? `${this.comission}${this.currencyView}` : ''
    }
  }
}
</script>

<style scoped>
  .apple-pay-button {
      display: inline-block;
      height: 48px;
      width: 100%;
      -webkit-appearance: -apple-pay-button;
      -apple-pay-button-type: tip; /* Use any supported button type. */
  }
  .apple-pay-button-black {
      -apple-pay-button-style: black;
  }
  .apple-pay-button-white {
      -apple-pay-button-style: white;
  }
  .apple-pay-button-white-with-line {
      -apple-pay-button-style: white-outline;
  }
  .pay-button-wrapper {
      margin-top: 15px;
  }

  .button {
      background: var(--main-button-color);
      height: 52px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--main-pay-button-font-color);
  }

  .inactive {
    background: var(--main-button-inactive-color);
    color: rgba(0, 0, 0, 0.5);
  }

  .comission-text {
    font-size: 10px;
  }
</style>
