<template>
  <div class="payment-type">
    <span @click="setType('card')" class="type active-payment-type">Карта</span>
    <span @click="setType('applePay')" v-if="isApplePayAvailable" class="type">Apple Pay</span>
  </div>
</template>

<script>
export default {
  name: 'PaymentType',
  data: function () {
    return {
      isApplePayAvailable: window.ApplePaySession
    }
  },
  methods: {
    setType(type = '') {
        const activeClass = 'active-payment-type';
        let activeType = document.getElementsByClassName(activeClass) || [];
        if (activeType[0]) {
            activeType[0].classList.remove(activeClass);
        }
        event.target.classList.add(activeClass);
        this.$emit('paymentTypeChanged', type);
    }
  }
}
</script>

<style scoped>
  .payment-type {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 26px;
    border: 2px solid var(--main-button-color);
    border-radius: var(--main-block-border-radius);
    width: 99%;
    margin-top: 36px;
    background-color: var(--main-button-color);
  }
  .type {
      text-align: center;
      width: 100%;
      line-height: 26px;
      font-size: 13px;
      background-color: white;
      cursor: pointer;
      color: var(--main-payment-type-inactive-font-color);
  }

  .active-payment-type {
      background-color: var(--main-button-color);
      color: var(--main-payment-type-font-color);
  }

  .second {
      border-right: 2px solid var(--main-button-color);
  }

  span:first-child {
    border-radius: var(--main-payment-type-border-radius);
  }

  span:last-child {
    border-radius: var(--main-payment-type-border-radius);
  }
</style>
