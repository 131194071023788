import collectBrowserInfo from '../vendor/3dsv2helper';

const STATE = {
    OFFER: 'offer',
    RESULT: 'result',
    REDIRECT: 'redirect',
    IN_PROGRESS: 'in_progress',
    AUTHENTICATION: 'authentication',
    THREEDS2PREPARE: '3ds2_prepare',
    IFRAME: 'iframe'
};

export default {
    methods: {
        /* eslint-disable */
        checkStatePayment: async function (response, token, callback = () => {}) {
            const { state } = response.data;
            this.isLoading = true;
            switch (state) {
            case STATE.IN_PROGRESS:
                this.isLoading = true;
                const stateResp = await this.$http.get(`payment/${token}`);
                this.checkStatePayment(stateResp, token, callback);
                break;
            case STATE.THREEDS2PREPARE:
                const version = response.data.versions[0];
                const params = collectBrowserInfo(version);
                const prepareResp = await this.$http.post(`payment/${token}/3ds2-prepare/accept`, params);
                this.checkStatePayment(prepareResp, token, callback);
                break;
            case STATE.IFRAME:
            case STATE.REDIRECT:
                window.scrollTo(0, 0);
                this.redirectToIframe(response, token);
                break;
            case STATE.AUTHENTICATION:
            case STATE.OFFER: {
                console.log('Offer state don\'t implement');
                break;
            }
            case STATE.RESULT: {
                const stateData = { state: 'bill', token, data: response.data };
                this.$emit('changeStep', stateData);
                callback(stateData);
                this.isLoading = false;
                break;
            }
            }
        }
    },
  };
  