/**
 * @function collectBrowserInfo
 *
 * @desc Collects available frontend browser info
 * and store it in the properties dictated by the EMVCo spec
 * (3DS_Spec_protocolAndCoreFny_v2-1_Oct2017.pdf)
 *
 * @example const browserInfo = collectBrowserInfo();
 * const userAgent = browserInfo.userAgent;
 *
 * @returns {Object} - browserInfo an object containing the retrieved browser properties
 */
/* eslint-disable */
const collectBrowserInfo = (version) => {

    const screenWidth = window && window.screen ? window.screen.width : '';
    const screenHeight = window && window.screen ? window.screen.height : '';
    const colorDepth = window && window.screen ? window.screen.colorDepth : '';
    const userAgent = window && window.navigator ? window.navigator.userAgent : '';
    const javaEnabled = window && window.navigator ? navigator.javaEnabled() : false;
  
    let language = '';
    if (window && window.navigator) {
      language = window.navigator.language
        ? window.navigator.language
        : window.navigator.browserLanguage; // Else is for IE <+ 10
  
      if (language.indexOf('-') > -1) {
        const arrayStr = language.split('-');
        language = `${arrayStr[0]}-${arrayStr[1].toUpperCase()}`;
      }
    }
  
    const d = new Date();
    const timeZoneOffset = d.getTimezoneOffset();
  
    const browserInfo = {
      'version': version,
      'device.channel': 'BRW',
      'device.browserAcceptHeader': 'text/html',
      'device.browserScreenWidth': screenWidth,
      'device.browserScreenHeight': screenHeight,
      // 'device.browserColorDepth': colorDepth,
      'device.browserColorDepth': 32,
      'device.browserUserAgent': userAgent,
      'device.browserTZ': timeZoneOffset,
      'device.browserLanguage': language,
      'device.browserJavaEnabled': javaEnabled,
      'challengeWindowSize': '04'
    };
  
    return browserInfo;
  };
  
  export default collectBrowserInfo;
  