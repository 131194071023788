<template>
  <div id="app">
    <loader v-if="isLoading"/>
    <div v-else>
      <payment-page v-if="paymentId && step == 'payment'" :invoiceId="invoiceId"  :paymentId="paymentId" @changeStep="changeStep"/>
      <error-page :type="errorType" v-if="!paymentId && errorType"/>
      <bill v-if="step == 'bill' && token" :paymentId="paymentId" :token="token" :billData="billData" @changeStep="changeStep"/>
    </div>
  </div>
</template>

<script>
import PaymentPage from './components/PaymentPage.vue'
import ErrorPage from './components/ErrorPage.vue'
import Bill from './components/Bill.vue';
import Loader from './components/Loader.vue'
import paymentStatus from './mixins/paymentStatus';

export default {
  name: 'App',
  mixins: [paymentStatus],
  components: {
    PaymentPage,
    ErrorPage,
    Bill,
    Loader
  },
  data: function () {
    return {
      step: 'payment',
      paymentId: null,
      invoiceId: null,
      billData: null,
      isLoading: false
    }
  },
  computed: {
    errorType: function () {
      let type = null;
      if (!this.paymentId) {
        type = 'link';
      }
      return type;
    }
  },
  created: async function () {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.invoiceId = urlParams.get('invoiceId');
    this.paymentId = urlParams.get('paymentId') || this.invoiceId;
    if (urlParams.get('state') && urlParams.get('state') === 'bill') {
      this.isLoading = true;
      const token = urlParams.get('token');
      const stateResp = await this.$http.get(`payment/${token}`);
      await this.checkStatePayment(stateResp, token, this.changeStep);
    }
    Date.prototype.getMonthFormatted = function() {
      var month = this.getMonth() + 1;
      return month < 10 ? '0' + month : month;
    }
    Date.prototype.getDateFormatted = function() {
      var date = this.getDate();
      return date < 10 ? '0' + date : date;
    }
    Array.prototype.remove = function() {
      var what, a = arguments, L = a.length, ax;
      while (L && this.length) {
          what = a[--L];
          while ((ax = this.indexOf(what)) !== -1) {
              this.splice(ax, 1);
          }
      }
      return this;
    };
  },
  methods: {
    changeStep(data) {
        this.step = data.state;
        if(data.token) {
          this.token = data.token;
        }
        if(data.data) {
          this.billData = data.data;
        }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500&display=swap');
  /* http://meyerweb.com/eric/tools/css/reset/ 
    v2.0 | 20110126
    License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  @font-face {
    font-family: 'bullet';
    src: url('./assets/fonts/Bullet/bullet.otf');
  }
  body {
    line-height: 1;
    font-family: Roboto, 'sans-serif';
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .content-wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  *:focus {
    outline: none;
  }

  input:focus{
    outline: none;
  }

  input {
    border: 1px solid rgba(170, 170, 170, 0);
  }

  #app {
    height: 100vh;
  }

  .pan-wrapper {
        display: flex;
        margin-top: 8px;
        border-radius: 8px;
        align-items: center;
        border: 1px solid #fbce0700;
        background: #FCFCFC;
        box-shadow: var(--main-pan-wrapper-box-shadow);
  }
  .card-input {
      height: 50px;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 21px;
      background: #FCFCFC;
      border-radius: 12px;
      width: 86%;
      padding-left: 10px;
      margin-top: 8px;
  }
  .pan-input {
      flex-grow: 4;
      margin-left: 16px;
      width: auto;
      padding-left: inherit;;
      margin-top: auto;
  }
  .brand {
        flex-grow: 1;
  }
  .expiry-cvv-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
    }
  .expiry-label .cvv-label {
        font-size: 13px;
    }
  .expiry-cvc-wrapper {
        flex-grow: 1;
  }
  .expiry-cvc {
    box-shadow: var(--main-expiry-cvc-shadow);
  }
  .amount-input {
    width: 95%;
  }

  .amount-wrapper {
    position: relative;
  }

  .v-clip {
    border-color: var(--main-spinner-border-color) !important;
  }

  .button .v-spinner .v-clip {
    height: 25px !important;
    width: 25px !important;
  }
</style>
