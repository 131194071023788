<template>
  <div class="card-wrapper">
    <IV-input
        @input="panChanged"
        :classes="['pan-input card-input']"
        :wrapClasses="['pan-wrapper']"
        :placeholder="'XXXX XXXX XXXX XXXX'"
        :maskPattern="'#### #### #### #### ###'"
        :label="$t('pan.title')"
        :postfixImage="card.brand"
        :isError="errors.pan"
    />
    <div class="expiry-cvv-wrapper">
        <IV-input
                @input="expiryChanged"
                :classes="['card-input', 'expiry-cvc']"
                :wrapClasses="['expiry-cvc-wrapper']"
                :placeholder="'MM/YY'"
                :maskPattern="'##/##'"
                :label="$t('expiry.date')"
                :isError="errors.expiry"
        />
        <IV-input
                @input="cvcChanged"
                :resetValue="!isRequireCVC"
                :classes="['card-input', 'bullet-text', 'expiry-cvc']"
                :wrapClasses="['expiry-cvc-wrapper']"
                :placeholder="'111'"
                :maskPattern="'###'"
                :label="'CVC/CVV'"
                :isError="errors.cvv"
        />
    </div>
  </div>
</template>

<script>
import luhn from 'fast-luhn';
import IVInput from './Base/IVInput.vue';
import {MIN_PAN_LENGTH} from '../utils/constants';

const BRAND_CLASS = {
  VISA: 'visa',
  MASTER_CARD: 'mastercard',
  MAESTRO: 'maestro',
  MIR: 'mir',
  DEFAULT: 'default'
};

export default {
  components: { IVInput },
  name: 'Card',
  props: {
    card: Object,
    errors: { type: Object, required: true }
  },
  data: function () {
    return {
      isRequireCVC: true
    }
  },
  methods: {
    getUnmaskPan(pan = '') {
        return pan.replace(/\s/g, '')
    },
    monthValidator(value = '') {
        return !(value <= 0 || value > 12 || value.length < 2);
    },
    yearValidator(value = '', expiryMonth) {
        const currentYear = new Date().getFullYear().toString().substr(-2);
        const currentMonth = new Date().getMonthFormatted();
        return !(value < currentYear || (value && value.length < 2) ||
        (value === currentYear && expiryMonth && expiryMonth < currentMonth));
    },
    getBrandByPan(pan = '') {
        switch (parseInt(pan[0])) {
            case 2: {
            let numberPart = parseInt(pan.toString().substr(0, 4));
            if (numberPart >= 2200 && numberPart < 2205) {
                this.card.brand = BRAND_CLASS.MIR;
            }
            if (numberPart >= 2221 && numberPart < 2721) {
                this.card.brand = BRAND_CLASS.MASTER_CARD;
            }
            this.isRequireCVC = true;
            break;
            }
            case 4: {
            this.card.brand = BRAND_CLASS.VISA;
            this.isRequireCVC = true;
            break;
            }
            case 5: {
            let numberPart = parseInt(pan[1]);
            if (numberPart > 5 || numberPart < 1) {
                this.card.brand = BRAND_CLASS.MAESTRO;
                this.isRequireCVC = false;
                this.card.cvv = '';
                this.errors.cvv = false;
            } else {
                this.card.brand = BRAND_CLASS.MASTER_CARD;
                this.isRequireCVC = true;
            }
            break;
            }
            case 6: {
                this.card.brand = BRAND_CLASS.MAESTRO;
                this.isRequireCVC = false;
                this.isRequireCVC = false;
                this.card.cvv = '';
                this.errors.cvv = false;
            break;
            }
            default: {
            this.card.brand = BRAND_CLASS.DEFAULT;
            this.isRequireCVC = true;
            }
      }
    },
    isValidPan(pan) {
        return luhn(pan) && pan.length >= MIN_PAN_LENGTH;
    },
    checkValidate() {
        return;
    },
    expiryChanged(value) {
        const [month, year] = value.split('/');
        const isMonthValid = this.monthValidator(month);
        const isYearValid = this.yearValidator(year, month);
        this.errors.expiry = !(isMonthValid && isYearValid);
        this.card.expiry.month = month;
        this.card.expiry.year = year;
    },
    panChanged(value) {
        const unmaskPan = this.getUnmaskPan(value);
        this.getBrandByPan(value);
        this.card.pan = unmaskPan;
        const pan = unmaskPan;
        this.errors.pan = !luhn(pan) || pan.length < MIN_PAN_LENGTH;
        if(this.card.brand === BRAND_CLASS.MAESTRO) {
            this.errors.cvv = false;
        }
    },
    cvcChanged(value) {
        if (this.card.brand === BRAND_CLASS.MAESTRO && (value.length > 0 && value.length < 3)) {
            this.errors.cvv = true;
        } else {
            this.errors.cvv = false;
        }
        if (this.card.brand !== BRAND_CLASS.MAESTRO) {
            (this.isRequireCVC && value.length === 3) ? this.errors.cvv = false : this.errors.cvv = true;
        }
        this.card.cvv = value;
    }
  },
  async created() {
    // 
  }
}
</script>

<style scoped>
    .card-wrapper {
        padding-top: var(--main-card-wrapper-padding-top);
    }
</style>
