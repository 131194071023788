<template>
  <div class="offer-wrapper">
    <div class="offer-text">
        <p>{{ $t('offer.agree') }} <a class="offer-link" :href="offerLink" target="_blank">{{ $t('offer.link') }}</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  data: function () {
    return {
      offerLink: window.settings.offerLink,
    }
  },
}
</script>

<style scoped>
  .offer-wrapper {
      margin-top: 15px;
  }

  .offer-text {
      display: flex;
      font-size: 12px;
      color: #ACACAC;
      justify-content: center;
      text-align: center;
  }

  .offer-link {
      text-decoration: underline;
      color: #ACACAC;
  }
</style>
