import Vue from 'vue'
import App from './App.vue'
import axios from 'axios';
import VueI18n from 'vue-i18n';
import ru from './i18n/ru';
import en from './i18n/en';
// import qs from 'query-string';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const locale = urlParams.get('lang') || 'ru';

const { settings } = window;
if (settings === undefined) throw new Error('[Critical error]: broken or invalid settings!');

const axiosInstance = axios.create({
    baseURL: `${settings.openApi.url}/${settings.openApi.portalId}`
});

axiosInstance.interceptors.request.use((request) => {
  if (request && typeof request.data === 'object') {
    for (let key in request.data ) {
      if (!request.data[key] && request.data[key] !== false && request.data[key] !== 0) {
        delete request.data[key];
      }
    }
  }
  let serialize = function(obj = {}, prefix) {
    var str = [],
      p;
    for (p in obj) {
      // eslint-disable-next-line
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          serialize(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  }
  
  if(request.url.match('applepay/accept')) {
    delete request.headers['content-type'];
    request.headers['content-type'] = 'application/json';
  } else {
    request.headers['content-type'] = 'application/x-www-form-urlencoded';
    if (request && typeof request.data === 'object') {
      request.data = serialize(request.data);
    }
  }

  return request;
});

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale,
  messages: {
    ru,
    en
  }
});

Vue.config.productionTip = false
Vue.prototype.$http = Vue.http = axiosInstance

new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app')