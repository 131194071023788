export default {
    methods: {
        /* eslint-disable */
        getCommission(amount = 0, rules = []) {
            let ruleForAmount = rules[0];
            let findRule = false;
            let { minAmount: minPlaceholder = rules[0].min, maxAmount: maxPlaceholder = 0 } = ruleForAmount;
            let fixed = 0;
            // eslint-disable-next-line
            for (const rule of rules) {
              const minAmount = rule.min;
              const maxAmount = rule.max;
        
              if (amount >= minAmount && amount <= maxAmount && !findRule) {
                fixed = rule.commission.fixed || 0;
                ruleForAmount = rule;
                findRule = true;
              }
              if (rule.min < minPlaceholder) {
                minPlaceholder = rule.min;
              }
              if (rule.max > maxPlaceholder) {
                maxPlaceholder = rule.max;
              }
            }
            if (!findRule && amount < minPlaceholder) {
              // eslint-disable-next-line prefer-destructuring
              ruleForAmount = rules[0];
            }
            if (!findRule && amount > maxPlaceholder) {
              // eslint-disable-next-line prefer-destructuring
              ruleForAmount = rules[rules.length - 1];
            }
            ruleForAmount.minPlaceholder = minPlaceholder;
            ruleForAmount.maxPlaceholder = maxPlaceholder + fixed;
            ruleForAmount.fixed = fixed;
            return this.calculateTotalAmountAndCommissionInfo(amount * 100, ruleForAmount);
        },
        calculateTotalAmountAndCommissionInfo(amount, rule, providerAlias = '') {
            // eslint-disable-next-line prefer-destructuring
            const commissionValue = rule.commission || { addValue: null };
            const fixedAddValue = rule.commission ? rule.commission.fixed : 0;
            const result = { amount };
            let percentPart = 0;
            let minPercentPart = 0;
            let maxPercentPart = 0;
            const addValue = commissionValue.addValue ? commissionValue.addValue : 0;
            if (commissionValue.percent) {
              result.percent = commissionValue.percent;
              percentPart = Math.round(amount * commissionValue.percent * 0.01) + addValue;
              minPercentPart = rule['min-sum'] ? Math.round(rule['min-sum'] * commissionValue.percent * 0.01) + addValue : 0;
              maxPercentPart = rule['max-sum'] ? Math.round(rule['max-sum'] * commissionValue.percent * 0.01) + addValue : 0;
            }
            if (commissionValue.min) {
              result.min = commissionValue.min;
              if (percentPart < commissionValue.min) {
                percentPart = commissionValue.min + addValue;
              }
              if (maxPercentPart < commissionValue.min) {
                maxPercentPart = commissionValue.min + addValue;
              }
              if (minPercentPart < commissionValue.min) {
                minPercentPart = commissionValue.min + addValue;
              }
            }
            if (addValue) {
              result.addValue = commissionValue.addValue;
            }
            if (rule['min-sum']) {
              result.minAmount = rule.minPlaceholder;
              result.minTotalAmount = rule.minPlaceholder + minPercentPart + fixedAddValue;
            } else {
              result.minAmount = 0;
              result.minTotalAmount = 0;
            }
            if (rule['max-sum'] || rule['max-sum'] === 0) {
              result.maxAmount = rule.maxPlaceholder;
              result.maxTotalAmount = rule.maxPlaceholder + maxPercentPart + fixedAddValue;
            } else {
              result.maxAmount = 0;
              result.maxTotalAmount = 0;
            }
            const totalAmount = amount + ((percentPart || addValue) + fixedAddValue);
            result.totalAmount = totalAmount;
            result.percentPart = (percentPart || addValue) + fixedAddValue;
            result.providerAlias = providerAlias;
            result.fixed = fixedAddValue;
            result.comission = (totalAmount - amount) / 100;
            return result;
        }
    }
}